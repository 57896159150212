document.querySelectorAll('.action-selector').forEach(element => {
    element.addEventListener('click', event => {
        //check if other actions for the same release are checked
        if(element.checked) {
            let release = element.className.match(/release-\d+/)[0];
            let id = element.id;
            document.querySelectorAll('.' + release).forEach(element => {
                if(element.id!==id){
                    element.checked = false;
                }
            });
        }
        DeliveryTable.checkAll();
    });

});

document.querySelectorAll('#delivery_all, #delete_all, #update_all, #redeliver_all').forEach(element => {
    element.addEventListener('change', event => {
        DeliveryTable.checkAll();
    });
});
//create DeliveryTable class
class DeliveryTable {

}

DeliveryTable.checkAll = function() {
    let selected = 0;
    document.querySelectorAll('.action-selector').forEach(element => {
        if(element.checked) {
            selected++;
        }
    });
    let submit = document.getElementById('action');
    if(selected>0){
        submit.style.display = 'block';
    }
    else {
        submit.style.display = 'none';
    }
}