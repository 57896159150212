//use chart.js to create a chart
import Chart from 'chart.js/auto';


//if document is loaded, create the chart
document.addEventListener('DOMContentLoaded', function() {
    const ctx = document.getElementById('streams-chart');


    // window.ctxData.options.plugins.legend = {
    //     position: 'right',
    //     align: 'start',
    //     labels: {
    //         color: "#e5e5e5", // White text
    //         usePointStyle: false, // Hide default legend boxes
    //         generateLabels: function(chart) {
    //             return chart.data.datasets.map((dataset, i) => {
    //                 return {
    //                     text: dataset.label, // Normal text label
    //                     fillStyle: dataset.borderColor,
    //                     hidden: !chart.isDatasetVisible(i),
    //                     datasetIndex: i
    //                 };
    //             });
    //         }
    //     },
    //     onClick: function(e, legendItem, legend) {
    //         const index = legendItem.datasetIndex;
    //         const ci = legend.chart;
    //         ci.setDatasetVisibility(index, !ci.isDatasetVisible(index));
    //         ci.update();
    //         updateLegendIcons(); // Refresh icons after clicking
    //     }
    // };



    if(ctx){
        //create the chart
        window.streamsChart = new Chart(ctx, window.ctxData);
    }
    else{
        console.log('no ctx');
    }

    // Function to modify legend items and inject FontAwesome icons
    function updateLegendIcons() {
        setTimeout(() => {
            const legendItems = document.querySelectorAll('.streams-chart-legend li');

            legendItems.forEach((item, index) => {
                const dataset = window.streamsChart.data.datasets[index];

                const isHidden = !window.streamsChart.isDatasetVisible(index);
                const iconClass = isHidden ? 'fa-regular fa-square' : 'fa-solid fa-square-check';
                const maxLabelLength = 30;

                let label = dataset.label + ' - ' + dataset.total;
                if(label.length > maxLabelLength){
                    let amount = label.substring(label.indexOf(' - '));
                    label = label.substring(0, label.indexOf(' - '));

                    label = label.substring(0, (maxLabelLength - amount.length)) + '...  ' + amount;


                }

                // Inject the FontAwesome icon
                item.innerHTML = `<i class="${iconClass}" style="margin-right: 5px;color:${dataset.borderColor}"></i> ${label}`;
            });
        }, 10); // Slight delay to allow legend rendering
    }

    // Apply the legend override after rendering
    setTimeout(() => {
        const legendContainer = document.querySelector('.streams-chart-legend');
        if (!legendContainer) {
            const parent = document.getElementById("streams-chart").parentNode;
            const newLegend = document.createElement("ul");
            newLegend.className = "streams-chart-legend";
            newLegend.style.listStyle = "none";
            newLegend.style.padding = "0";
            newLegend.style.marginTop = "10px";

            // Generate custom legend items
            window.streamsChart.data.datasets.forEach((dataset, index) => {
                const li = document.createElement("li");
                li.style.cursor = "pointer";
                li.style.display = "flex";
                li.style.alignItems = "center";
                li.style.marginBottom = "5px";
                li.style.fontSize = "14px";
                //li.style.color = dataset.borderColor;
                li.dataset.index = index;
                li.addEventListener("click", function () {
                    window.streamsChart.setDatasetVisibility(index, !window.streamsChart.isDatasetVisible(index));
                    window.streamsChart.update();
                    updateLegendIcons();
                });

                // Default content (to be updated by updateLegendIcons)
                li.innerHTML = dataset.label;
                newLegend.appendChild(li);
            });

            //parent.appendChild(newLegend);
            //add new legend as first child in parent
            //parent.insertBefore(newLegend, parent.firstChild);
            let legend = document.getElementById('chart-legend');
            legend.insertBefore(newLegend, legend.firstChild);

            //document.getElementById('chart-legend').appendChild(newLegend);

            updateLegendIcons(); // Inject FontAwesome icons
        }
    }, 100);


    let periodSelector = document.getElementById('periodSelector');
    if(periodSelector){
        periodSelector.addEventListener('change', function(){
            let period = periodSelector.value;
            //get url without query string
            let url = window.location.href.split('?')[0];
            //add query string
            url += '?period=' + period;
            document.location.href = url;
        });
    }
});

